<div class="dataswap-container" style="height: 100%;">
  <div class="dataswap-container-title" style="height: 5%;">
    <ng-container *ngIf="!isInTab">
      <h1 style="text-align:center;">DataSwap</h1>
    </ng-container>
    <ng-container *ngIf="isInTab">
      <h5 style="text-align:center;">DataSwap</h5>
    </ng-container>
  </div>

  <div class="dataswap-container-main" style="height: 95%;" *ngIf="loadingFinished && integrationInformationList">
    <kendo-tabstrip height="100%" (tabSelect)="onTabSelect()">
      <kendo-tabstrip-tab title="Get File XML" [selected]="true">
        <ng-template kendoTabContent>
          <div class="flexWrap">
            <div class="flexInputCol">

              
              <div *ngIf="!isInTab">
                <kendo-switch style="width:160px" 
                    [(ngModel)]="selectByLTClient" 
                    [onLabel]="'LOS Talker Client'" 
                    [offLabel]="'Company Id and Name'" 
                    (valueChange)="changeSelectionMethod($event)"
                    *ngIf="checkAccess(['LOSTalker.DevOps','LOSTalker.Admin'])">
                </kendo-switch>
                <br>
                <br>
  
                <div *ngIf="!selectByLTClient">
  
                  <mat-form-field appearance="fill" style="min-width: 325px;" *ngIf="destinationListOptions.length > 1">
                    <mat-label>Destination</mat-label>
                    <mat-select
                        (selectionChange)="selectDestination($event.value)">
                      <mat-option *ngFor="let option of destinationListOptions" [value]="option" >
                        {{option}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <ng-container *ngIf="destinationListOptions.length == 1 && validDestination">
                    <h5>{{destination}}</h5>
                  </ng-container>
  
                  <br>
  
                  <mat-form-field *ngIf="validDestination" appearance="fill" style="min-width: 325px;" >
                    <mat-label>Identifier</mat-label>
                    <input type="text"
                      matInput
                      [matAutocomplete]="auto"
                      [formControl]="integrationInfoListFormControl">
                    <mat-autocomplete autoActiveFirstOption
                        #auto="matAutocomplete"
                        (optionSelected)="selectIdentifier($event.option.value)"
                        [displayWith]="displayFnIdentifier">
                      <mat-option *ngFor="let option of integrationInfoFilteredList | async" [value]="option" >
                        <span class="row"><span class="col-2">{{option.identifierIdValue}}</span> <span class="col-1"> - </span><span class="col">{{option.identifierNameValue}}</span></span>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
  
                </div>
  
                <div *ngIf="selectByLTClient">
                  <div *ngIf="!clientSelected">
                    <mat-form-field appearance="fill" style="min-width: 325px;">
                      <mat-label>Client</mat-label>
                      <input type="text"
                        matInput
                        [matAutocomplete]="auto"
                        [formControl]="clientFormControl">
                      <mat-autocomplete autoActiveFirstOption
                          #auto="matAutocomplete"
                          (optionSelected)="selectClient($event.option.value)"
                          [displayWith]="displayFnClient">
                        <mat-option *ngFor="let option of clientsFiltered | async" [value]="option">
                          {{option.name}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <br>
                  </div>
    
                  <div *ngIf="clientSelected">
                    <div style="display: flex; align-items: center; justify-content: space-between;">
                      <h2>Client: {{selectedClient.name}}</h2>
                      <button class="btn" style="float: right;" (click)="editClient()" *ngIf="!isInTab"><i class="bi bi-pencil"></i></button>
                    </div>
                    <br *ngIf="!clientAndIntegrationProvided">
                    <div *ngIf="!integrationSelected">
                      <mat-form-field appearance="fill" >
                        <mat-label>Integration</mat-label>
                        <input type="text"
                          matInput
                          [matAutocomplete]="auto"
                          [formControl]="integrationFormControl">
                        <mat-autocomplete autoActiveFirstOption
                            #auto="matAutocomplete"
                            (optionSelected)="selectIntegration($event.option.value)"
                            [displayWith]="displayFnIntegration">
                          <mat-option *ngFor="let option of integrationsFiltered | async" [value]="option" >
                            {{option.name}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      <br>
                    </div>
                  </div>
                
                  <div *ngIf="integrationSelected">
                    <div style="display: flex; align-items: center; justify-content: space-between;">
                      <h5>Integration: {{selectedIntegration.name}}</h5>
                      <button class="btn" style="float: right;" (click)="editIntegration()" *ngIf="!isInTab"><i class="bi bi-pencil"></i></button>
                    </div>
                    <br *ngIf="!clientAndIntegrationProvided">
                  </div>
  
                  <div *ngIf="integrationSelected && selectedIdentifier">
                    <h5>Selected ID: {{selectedIdentifier.destinationName}} - {{selectedIdentifier.identifierIdValue}} - {{selectedIdentifier.identifierNameValue}}</h5>
                    <br *ngIf="!clientAndIntegrationProvided">
                  </div>
                  <div *ngIf="integrationSelected && !selectedIdentifier">
                    <mat-form-field appearance="fill" style="width: 75%;">
                    <mat-label>Identifier</mat-label>
                    <input type="text" matInput [matAutocomplete]="auto" [formControl]="integrationInfoListFormControl">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selectIdentifier($event.option.value)"
                      [displayWith]="displayFnIdentifier">
                      <mat-option *ngFor="let option of integrationInfoFilteredList | async" [value]="option">
                        {{option.destinationName}} - {{option.identifierIdValue}} - {{option.identifierNameValue}}
                      </mat-option>
                    </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div *ngIf="isInTab">
                <div *ngIf="integrationInformationList.length == 1">
                  <br>
                  <h4>{{selectedIdentifier.destinationName}} - {{selectedIdentifier.identifierIdValue}} -
                  {{selectedIdentifier.identifierNameValue}}</h4>
                  <br>
                </div>
                <div *ngIf="integrationInformationList.length > 1">
                  <mat-form-field appearance="fill"style="min-width: 325px;" >
                    <mat-label>Identifier</mat-label>
                    <input type="text"
                      matInput
                      [matAutocomplete]="auto"
                      [formControl]="integrationInfoListFormControl">
                    <mat-autocomplete autoActiveFirstOption
                        #auto="matAutocomplete"
                        (optionSelected)="selectIdentifier($event.option.value)"
                        [displayWith]="displayFnIdentifier">
                      <mat-option *ngFor="let option of integrationInfoFilteredList | async" [value]="option" >
                        {{option.destinationName}} - {{option.identifierIdValue}} - {{option.identifierNameValue}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div *ngIf="integrationInformationList.length == 0">
                  <h4>No identifiers.</h4>
                </div>
              </div>

              <div *ngIf="!credentialsLoaded && selectedIdentifier">
                <div class="k-i-loading"></div>
              </div>
              <div *ngIf="credentialsLoaded && selectedIdentifier">
                  <mat-form-field appearance="fill" >
                    <mat-label>File Type Request</mat-label>
                    <input type="text"
                      matInput
                      [matAutocomplete]="auto"
                      [formControl]="filetypeFormControl">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="setFileType($event.option.value)">
                      <mat-option *ngFor="let option of fileTypeList"[value]="option" >
                        {{option}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <br>

                  <ng-container *ngIf="useAllOptions">
                    <mat-checkbox
                        [checked]="useAllOptions"
                        (change)="changeGetAllOptionsCheck($event.checked)">
                      Get All Latest Options
                    </mat-checkbox>
                  </ng-container>

                  <ng-container *ngIf="!useAllOptions">
                    <mat-checkbox
                        [checked]="useAllOptions"
                        (change)="changeGetAllOptionsCheck($event.checked)">
                      Get All Latest Options
                    </mat-checkbox>
                    <br>
                    <mat-form-field appearance="fill">
                      <mat-label>Latest No</mat-label>
                      <mat-select (selectionChange)="setLatestNo($event.value)">
                        <mat-option *ngFor="let option of latestNoOptions" [value]="option">
                          {{option}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                  <br>

                  <button kendoButton (click)="invokeGetFile()" >Invoke</button>

              </div>

            </div>


            <div class="flexMonacoCol">
              <ng-container *ngIf="displayMonaco0">
                <div style="height:100%;">
                  <app-monaco-editor #monaco
                    *ngIf="displayMonaco0"
                    (textChange)="updateData($event,0)"
                    [editorData]="monacoData0"
                    [lang]="'xml'"
                    [editAccess]="false">
                  </app-monaco-editor>
                  <ng-container *ngTemplateOutlet="saveCurrentFile"></ng-container>
                </div>
              </ng-container>


              <ng-container *ngIf="displayMonaco1 || displayMonaco2 || displayMonaco3">
                <kendo-tabstrip height="100%">
                  <kendo-tabstrip-tab title="Latest #1" [selected]="true">
                    <ng-template kendoTabContent>
                      <app-monaco-editor #monaco1
                        *ngIf="displayMonaco1"
                        (textChange)="updateData($event,1)"
                        [editorData]="monacoData1"
                        [lang]="'xml'"
                        [editAccess]="false">
                      </app-monaco-editor>
                      <ng-container *ngTemplateOutlet="saveAllFile"></ng-container>
                    </ng-template>
                  </kendo-tabstrip-tab>
                  <kendo-tabstrip-tab title="Latest #2" >
                    <ng-template kendoTabContent>
                      <app-monaco-editor #monaco2
                        *ngIf="displayMonaco2"
                        (textChange)="updateData($event,2)"
                        [editorData]="monacoData2"
                        [lang]="'xml'"
                        [editAccess]="false">
                      </app-monaco-editor>
                      <ng-container *ngTemplateOutlet="saveAllFile" ></ng-container>
                    </ng-template>
                  </kendo-tabstrip-tab>
                  <kendo-tabstrip-tab title="Latest #3" >
                    <ng-template kendoTabContent>
                      <app-monaco-editor #monaco3
                        *ngIf="displayMonaco3"
                        (textChange)="updateData($event,3)"
                        [editorData]="monacoData3"
                        [lang]="'xml'"
                        [editAccess]="false">
                      </app-monaco-editor>
                      <ng-container *ngTemplateOutlet="saveAllFile"></ng-container>
                    </ng-template>
                  </kendo-tabstrip-tab>
                </kendo-tabstrip>
              </ng-container>
            </div>

          </div>

        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Get Loan XML">
        <ng-template kendoTabContent>
          <div class="flexWrap">
            <div class="flexInputCol">

              <div *ngIf="!isInTab">
                <kendo-switch style="width:160px" 
                    [(ngModel)]="selectByLTClient" 
                    [onLabel]="'LOS Talker Client'"
                    [offLabel]="'Company Id and Name'" 
                    (valueChange)="changeSelectionMethod($event)"
                    *ngIf="checkAccess(['LOSTalker.DevOps','LOSTalker.Admin'])">
                </kendo-switch>
                <br>
                <br>
  
                <div *ngIf="!selectByLTClient">
  
                  <mat-form-field appearance="fill" style="min-width: 325px;" *ngIf="destinationListOptions.length > 1">
                    <mat-label>Destination</mat-label>
                    <mat-select
                        (selectionChange)="selectDestination($event.value)">
                      <mat-option *ngFor="let option of destinationListOptions" [value]="option" >
                        {{option}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <ng-container *ngIf="destinationListOptions.length == 1 && validDestination">
                    <h5>{{destination}}</h5>
                  </ng-container>
  
                  <br>
  
                  <mat-form-field *ngIf="validDestination" appearance="fill"style="min-width: 325px;" >
                    <mat-label>Identifier</mat-label>
                    <input type="text"
                      matInput
                      [matAutocomplete]="auto"
                      [formControl]="integrationInfoListFormControl">
                    <mat-autocomplete autoActiveFirstOption
                        #auto="matAutocomplete"
                        (optionSelected)="selectIdentifier($event.option.value)"
                        [displayWith]="displayFnIdentifier">
                      <mat-option *ngFor="let option of integrationInfoFilteredList | async" [value]="option" >
                        <span class="row"><span class="col-2">{{option.identifierIdValue}}</span> <span class="col-1"> - </span><span class="col">{{option.identifierNameValue}}</span></span>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
  
                </div>
  
                <div *ngIf="selectByLTClient">
                  <div *ngIf="!clientSelected">
                    <mat-form-field appearance="fill" style="min-width: 325px;">
                      <mat-label>Client</mat-label>
                      <input type="text"
                        matInput
                        [matAutocomplete]="auto"
                        [formControl]="clientFormControl">
                      <mat-autocomplete autoActiveFirstOption
                          #auto="matAutocomplete"
                          (optionSelected)="selectClient($event.option.value)"
                          [displayWith]="displayFnClient">
                        <mat-option *ngFor="let option of clientsFiltered | async" [value]="option">
                          {{option.name}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <br>
                  </div>
    
                  <div *ngIf="clientSelected">
                    <div style="display: flex; align-items: center; justify-content: space-between;">
                      <h2>Client: {{selectedClient.name}}</h2>
                      <button class="btn" style="float: right;" (click)="editClient()"><i class="bi bi-pencil"></i></button>
                    </div>
                    <br *ngIf="!clientAndIntegrationProvided">
                    <div *ngIf="!integrationSelected">
                      <mat-form-field appearance="fill" >
                        <mat-label>Integration</mat-label>
                        <input type="text"
                          matInput
                          [matAutocomplete]="auto"
                          [formControl]="integrationFormControl">
                        <mat-autocomplete autoActiveFirstOption
                            #auto="matAutocomplete"
                            (optionSelected)="selectIntegration($event.option.value)"
                            [displayWith]="displayFnIntegration">
                          <mat-option *ngFor="let option of integrationsFiltered | async" [value]="option" >
                            {{option.name}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      <br>
                    </div>
                  </div>
    
                  <div *ngIf="integrationSelected">
                    <div style="display: flex; align-items: center; justify-content: space-between;">
                      <h5>Integration: {{selectedIntegration.name}}</h5>
                      <button class="btn" style="float: right;" (click)="editIntegration()"><i class="bi bi-pencil"></i></button>
                    </div>
                    <br *ngIf="!clientAndIntegrationProvided">
                  </div>
                  
                  <div *ngIf="integrationSelected && selectedIdentifier">
                    <h5>Selected ID: {{selectedIdentifier.destinationName}} - {{selectedIdentifier.identifierIdValue}} -
                      {{selectedIdentifier.identifierNameValue}}</h5>
                    <br *ngIf="!clientAndIntegrationProvided">
                  </div>
                  <div *ngIf="integrationSelected && !selectedIdentifier">
                    <mat-form-field appearance="fill">
                      <mat-label>Identifier</mat-label>
                      <input type="text" matInput [matAutocomplete]="auto" [formControl]="integrationInfoListFormControl">
                      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                        (optionSelected)="selectIdentifier($event.option.value)" [displayWith]="displayFnIdentifier">
                        <mat-option *ngFor="let option of integrationInfoFilteredList | async" [value]="option">
                          {{selectedIdentifier.destinationName}} - {{selectedIdentifier.identifierIdValue}} - {{selectedIdentifier.identifierNameValue}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div *ngIf="isInTab">
                <div *ngIf="integrationInformationList.length == 1">
                  <br>
                  <h4>{{selectedIdentifier.destinationName}} - {{selectedIdentifier.identifierIdValue}} -
                  {{selectedIdentifier.identifierNameValue}}</h4>
                  <br>
                </div>
                <div *ngIf="integrationInformationList.length > 1">
                  <mat-form-field appearance="fill"style="min-width: 325px;" >
                    <mat-label>Identifier</mat-label>
                    <input type="text"
                      matInput
                      [matAutocomplete]="auto"
                      [formControl]="integrationInfoListFormControl">
                    <mat-autocomplete autoActiveFirstOption
                        #auto="matAutocomplete"
                        (optionSelected)="selectIdentifier($event.option.value)"
                        [displayWith]="displayFnIdentifier">
                      <mat-option *ngFor="let option of integrationInfoFilteredList | async" [value]="option" >
                        {{option.destinationName}} - {{option.identifierIdValue}} - {{option.identifierNameValue}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div *ngIf="integrationInformationList.length == 0">
                  <h4>No identifiers.</h4>
                </div>
              </div>

              <div *ngIf="!credentialsLoaded && selectedIdentifier">
                <div class="k-i-loading"></div>
              </div>
              <div *ngIf="credentialsLoaded && selectedIdentifier">

                <mat-form-field appearance="fill" >
                  <mat-label>Loan Number</mat-label>
                  <input type="text"
                    matInput
                    [(ngModel)]="loanNumber">
                </mat-form-field>
                <br>

                <ng-container *ngIf="useAllOptions">
                  <mat-checkbox
                      [checked]="useAllOptions"
                      (change)="changeGetAllOptionsCheck($event.checked)">
                    Get All Latest Options
                  </mat-checkbox>
                </ng-container>

                <ng-container *ngIf="!useAllOptions">
                  <mat-checkbox
                      [checked]="useAllOptions"
                      (change)="changeGetAllOptionsCheck($event.checked)">
                    Get All Latest Options
                  </mat-checkbox>
                  <br>
                  <mat-form-field appearance="fill">
                    <mat-label>Latest No</mat-label>
                    <mat-select (selectionChange)="setLatestNo($event.value)">
                      <mat-option *ngFor="let option of latestNoOptions" [value]="option">
                        {{option}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>

                <br>

                <button kendoButton (click)="invokeGetLoan()" >Invoke</button>
              </div>

            </div>

            <div class="flexMonacoCol">
              <ng-container *ngIf="displayMonaco0">
                <div style="height:100%;">
                  <app-monaco-editor #monaco
                    *ngIf="displayMonaco0"
                    (textChange)="updateData($event,0)"
                    [editorData]="monacoData0"
                    [lang]="'xml'"
                    [editAccess]="false">
                  </app-monaco-editor>
                  <ng-container *ngTemplateOutlet="saveCurrentFilen"></ng-container>
                </div>
              </ng-container>

              <ng-container *ngIf="displayMonaco1 || displayMonaco2 || displayMonaco3">
              <kendo-tabstrip height="100%">
                <kendo-tabstrip-tab title="Latest #1" [selected]="true">
                  <ng-template kendoTabContent>
                    <app-monaco-editor #monaco1
                      *ngIf="displayMonaco1"
                      (textChange)="updateData($event,1)"
                      [editorData]="monacoData1"
                      [lang]="'xml'"
                      [editAccess]="false">
                    </app-monaco-editor>
                    <ng-container *ngTemplateOutlet="saveAllFile"></ng-container>
                  </ng-template>
                </kendo-tabstrip-tab>
                <kendo-tabstrip-tab title="Latest #2" >
                  <ng-template kendoTabContent>
                    <app-monaco-editor #monaco2
                      *ngIf="displayMonaco2"
                      (textChange)="updateData($event,2)"
                      [editorData]="monacoData2"
                      [lang]="'xml'"
                      [editAccess]="false">
                    </app-monaco-editor>
                    <ng-container *ngTemplateOutlet="saveAllFile"></ng-container>
                  </ng-template>
                </kendo-tabstrip-tab>
                <kendo-tabstrip-tab title="Latest #3" >
                  <ng-template kendoTabContent>
                    <app-monaco-editor #monaco3
                      *ngIf="displayMonaco3"
                      (textChange)="updateData($event,3)"
                      [editorData]="monacoData3"
                      [lang]="'xml'"
                      [editAccess]="false">
                    </app-monaco-editor>
                    <ng-container *ngTemplateOutlet="saveAllFile"></ng-container>
                  </ng-template>
                </kendo-tabstrip-tab>
              </kendo-tabstrip>
            </ng-container>
            </div>

          </div>
        </ng-template>
      </kendo-tabstrip-tab>

    </kendo-tabstrip>
  </div>

  <div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="!loadingFinished">
    <div class="k-i-loading"></div>
  </div>

  <div style="margin: auto; margin-top:20%; text-align: center;" *ngIf="loadingFinished && !integrationInformationList">
    <div class="no-records-message">Loading failed.</div>
  </div>

</div>

<ng-template #saveCurrentFile>
  <hr />
  <span class="center-save-buttons">
    <button mat-raised-button class="mx-2" (click)="saveFile()">Save Current File</button>
  </span>
</ng-template>
<ng-template #saveAllFile>
  <hr/>
  <span class="center-save-buttons">
    <button mat-raised-button class="mx-2" (click)="saveFile()">Save Current File</button>
    <button mat-raised-button class="mx-2" (click)="saveAllFiles()">Save All Files</button>
  </span>
  
</ng-template>