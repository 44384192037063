import { Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit, AfterViewChecked, AfterContentChecked, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { Location } from "@angular/common";
import { TabStripComponent } from "@progress/kendo-angular-layout";
import { forkJoin, Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Client } from "../../models/client";
import { Integration } from "../../models/integration";
import { IntegrationJavaScript } from "../../models/integration-javascript";
import { Server } from "../../models/server";
import { User } from "../../models/user";
import { MonacoPageComponent } from "../monaco-editor/monaco-editor.component";
import { ClientIntegrationService } from "../../services/client-integration.service";
import { ToastService } from '../../services/toast.service';
import { ErrorMessagesv2Component } from "../error-messages-v2/error-messages-v2.component";
import { CommonErrorFilterComponent } from "../common-error-filter/common-error-filter.component";
import { Subject } from "rxjs";
import { IdentifierService } from '../../services/identifier.service';
import { ClientContractService } from "src/app/services/client-contract.service";
import { IntegrationIdentifier } from '../../models/integration-identifier';
import { IntegrationInformation } from "src/app/models/integration-information";
import { UntypedFormControl, FormControl, Validators } from "@angular/forms";
import { IdentifierViewComponent } from "../identifier-view/identifier-view.component";
import { ServerService } from "src/app/services/server.service";
import { LOSTalkerTitleService } from "../../services/lostalker-title-service.service";
import { IntegrationRuntimeComponent } from "../integration-runtime/integration-runtime.component";
import { TypeConstantService } from "src/app/services/type-constant.service";
import { DestinationEndpoint } from "src/app/models/destination-endpoint";
import { SQLQueriesComponent } from "../sql-queries/sql-queries.component";
import { CustomSQLQueryService } from '../../services/sql-queries.service';
import { CustomSQLQuery } from '../../models/sql-query';
import { ClientContract } from "src/app/models/client-contract";
import { EditIntegrationComponent } from "../edit-integration/edit-integration.component";
import { FailedLoansViewComponent } from "../failed-loans-view/failed-loans-view.component";
import { IntegrationRevisionComponent } from "../integration-revision/integration-revision.component";
import { FastfetchComponent } from "../fastfetch/fastfetch.component";
import { LBADataswapComponent } from "../lba-dataswap/lba-dataswap.component";

@Component({
  selector: "app-edit-config",
  templateUrl: "./edit-config.component.html",
  styleUrls: ["./edit-config.component.scss"],
  providers: [ClientIntegrationService, IdentifierService, LOSTalkerTitleService],
})
export class EditConfigComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked {
  @ViewChild("monaco") public monaco: MonacoPageComponent;
  @ViewChild("tabstrip") public tabstrip: TabStripComponent;
  @ViewChild("errorListV2") public errorListV2: ErrorMessagesv2Component;
  @ViewChild("integrationRuntime") public integrationRuntime: IntegrationRuntimeComponent;
  @ViewChild("editIntegrationName") public editIntegrationName: EditIntegrationComponent;
  @ViewChild("errorFilter") public errorFilter: CommonErrorFilterComponent;
  @ViewChild("lbaDataSwap") public lbaDataSwap: LBADataswapComponent;
  @ViewChild("fastFetch") public fastFetch: FastfetchComponent;
  @ViewChild("revisionHistory") public revisionHistory: IntegrationRevisionComponent;
  @ViewChild('addJsDialog', { static: true }) addJsDialog: TemplateRef<any>;
  @ViewChild('clientInfoDialog', { static: true }) clientInfoDialog: TemplateRef<any>;
  @ViewChild('clientContractDialog', { static: true }) clientContractDialog: TemplateRef<any>;
  @ViewChild('editIntegrationDialog', { static: true }) editIntegrationDialog: TemplateRef<any>;
  @ViewChild("identifierTab") public identifierTab: IdentifierViewComponent;
  @ViewChild("queriesTab") public queriesTab: SQLQueriesComponent;
  @ViewChild("failedLoanView") public failedLoanViewTab: FailedLoansViewComponent;

  @ViewChild('clientContractDeleteConfirmationDialog', { static: true }) clientContractDeleteConfirmationDialog: TemplateRef<any>;
  public clientContractTermDate: Date;

  activeUser: User;
  client: Client;
  integrationId: string;
  serverDescription: string;
  javaScript: IntegrationJavaScript;
  lang: string = "xml";
  serverList: Server[];
  integrationServer: Server;
  integration: Integration;
  integrations: Integration[];
  destinationEndpoints: DestinationEndpoint[];
  // currentMetrics: IntegrationMetrics[];
  // allMetrics: IntegrationMetrics[];
  integrationSelected: boolean = false;
  javaScriptChanged: boolean = false;
  //javaScriptId: string;
  tempLocation: any;
  integrationsReload: boolean = false;
  showProcessDataCards: boolean = true;
  showDataSwap: boolean = false;
  showFastFetch: boolean = false;
  validDestinations = ["CSDataSwap", "Surefire3", "LGDataSwap", "SimpleNexus", "TotalExpert"];
  availableDestinations = this.validDestinations;
  xmlSelected: boolean = true;
  componentDestroyed$: Subject<boolean> = new Subject();
  //monacoHistoryData = "";
  monacoData = "";
  loaded: boolean = false;
  toastOps = { autoClose: false, keepAfterRouteChange: true };
  serverSelector: UntypedFormControl = new UntypedFormControl();
  savingInformation: boolean = false;
  convertCreateNew: boolean = false;
  clientContractDialogObject: MatDialogRef<any, any>;
  integrationHasQueries: boolean = false;
  runTimesPopulated: boolean = false;
  customQueries: CustomSQLQuery[];
  clientContracts: ClientContract[];
  contractsLoaded: boolean = false;
  clientIdentifiers: IntegrationIdentifier[];

  identifiers: IntegrationIdentifier[];
  integrationInfo: IntegrationInformation[] = [];
  showIdentifiersComments = false;
  hasNoIntegrations: boolean = false;
  tabId: number;
  tabSet: boolean = false;
  originalIntegrationXML: string;

  constructor(
    private clientIntegrationService: ClientIntegrationService,
    private serverService: ServerService,
    private identifierService: IdentifierService,
    private clientContractService: ClientContractService,
    private toastService: ToastService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location,
    private user: User,
    private router: Router,
    private titleService: LOSTalkerTitleService,
    private typeConstantsService: TypeConstantService,
    private customQueriesService: CustomSQLQueryService
  ) {
    this.activeUser = user;
    this.clientContractTermDate = new Date();
  }

  //Component Initialization Methods Start
  ngOnInit(): void {
    this.typeConstantsService.getAllDestinationEndpoints()
      .subscribe((d) => {
        this.destinationEndpoints = d;
      });
  }

  ngAfterContentChecked(): void { 
    this.initializeTabstrip();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => this.initializeMonacoComponent());
  }

  ngOnDestroy() {
    this.dialog.closeAll();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  initializeTabstrip(){
    if (!this.tabSet && this.tabstrip != undefined) {
      if (this.tabId < this.tabstrip.tabs.length) {
        //this.tabstrip.tabs.get(0).selected = false;
        this.tabstrip.tabs.get(this.tabId).selected = true;
      }
      else if (this.tabId >= this.tabstrip.tabs.length) {
        let clientName = encodeURI(this.client.name).replace("(", "%28").replace(")", "%29");
        let integrationName = encodeURI(this.integration.name).replace("(", "%28").replace(")", "%29");
        window.history.replaceState({}, '', `/editConfig/${clientName}/${integrationName}/0`);
      }
      else {
        this.tabstrip.tabs.get(0).selected = true;
      }
      this.tabSet = true;
    }
  }

  initializeMonacoComponent(): void {
    this.route.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((params) => {
        if (this.isValidClientId(params["client"])) {
          this.clientIntegrationService
            .getClientById(params["client"])
            .subscribe((c) => {

              this.initializeClient(params, c);

            }, error => {

              this.router.navigate(["/clients"]);

            });
        }
        else {
          this.clientIntegrationService
            .getClientByName(params["client"])
            .subscribe((c) => {

              this.initializeClient(params, c);

            }, error => {

              this.router.navigate(["/clients"]);

            });
        }

        this.tabId = params['tab'];
        if (this.tabId == null) {
          this.tabSet = true;
          this.tabId = 0;
        }

      });
  }

  initializeClient(params: Params, c: Client): void {
    this.client = c;
    this.titleService.setUpTitle("Client - " + this.client.name);

    this.clientIntegrationService
      .getIntegrationsByClient(this.client.name)
      .subscribe((i) => {
        this.integrations = i;
        this.serverService.getAllServersInformation().subscribe((s) => {
          this.serverList = s;

          if (this.integrations.length > 0) {
            if (params["integration"] != null) {
              if (this.isValidGuid(params["integration"])) {
                this.integration = this.integrations.find(
                  (x) => x.integrationId == params["integration"]);
              }
              else {
                this.integration = this.integrations.find(
                  (x) => x.name == params["integration"]);
              }
              if (this.integration != null) {
                this.selectIntegration(
                  this.integration
                );

                this.javaScript = this.integration.javaScript[0];

              }
              else {
                this.toastService.toastCreate("Integration " + params["integration"] + " not found.", "Warning", {
                  autoClose: false
                });
                this.integration = this.integrations[0];
                this.selectIntegration(this.integration);
                this.javaScript = this.integration.javaScript[0];
              }

            } else {
              this.integration = this.integrations[0];
              this.selectIntegration(this.integration);
              this.javaScript = this.integration.javaScript[0];
            }

            this.monacoData = this.integration.xmlConfig;
            if (
              this.integration.serverId != undefined &&
              this.integration.serverId != "0"
            ) {
              this.integrationServer = s.find(
                (x) => x.serverId == this.integration.serverId
              );
              this.serverDescription =
                "Running on " + this.integrationServer.description;
            } else {
              this.serverDescription =
                "Not Currently Running on the Server";
            }

            //Check for contracts with bad identifiers
            forkJoin({
              contracts: this.clientContractService.getAllClientContractsByClient(this.client.clientId),
              identifiers: this.identifierService.getIntegrationIdentifiersByClient(this.client.clientId)
            })
              .subscribe(({ contracts, identifiers }) => {
                this.clientContracts = contracts;
                this.contractsLoaded = true;
                this.clientIdentifiers = identifiers;
                for (let contract of contracts) {
                  if (identifiers.findIndex((x) => x.identifierIdValue == contract.identifierIdValue && x.destinationId == contract.destinationEndpointId) == -1) {
                    this.toastService.toastCreate("No identifier matching contract " + contract.identifierIdValue + ".", "Warning", {
                      autoClose: false
                    });
                  }
                }
              });
          } else {
            this.hasNoIntegrations = true;
          }
        });
      });
  }

  goToAddIntegration(): void {
    this.router.navigate(["/addIntegration", this.client.name]);
  }

  deleteClient(): void {
    this.savingInformation = true;

    this.clientIntegrationService.deleteClient(this.client).subscribe(() => {
      this.toastService.toastCreate("Client " + this.client.name + " deleted.", "Success", {
        autoClose: true,
        keepAfterRouteChange: true
      });
      this.router.navigate(["/clients"]);
      this.savingInformation = false;
    });
  }

  getActiveTabId(): number {
    if (this.tabstrip != null) {
      for (let i = 0; i < this.tabstrip.tabs.length; i++) {
        if (this.tabstrip.tabs.get(i).selected) {
          return i;
        }
      }
    }
    else{
      return 0;
    }
  }

  selectNewIntegration(i: Integration) {
    // Update URL with link directly to integration
    let clientName = encodeURI(this.client.name).replace("(", "%28").replace(")", "%29");
    let integrationName = encodeURI(i.name).replace("(", "%28").replace(")", "%29");
    window.history.replaceState({}, '', `/editConfig/${clientName}/${integrationName}/${this.getActiveTabId() ?? "0"}`);
    this.showProcessDataCardView();

    this.serverSelector.setValue(undefined);

    this.integration = i;

    this.selectIntegration(i);
    this.javaScript = i.javaScript[0];
    if (this.identifierTab) {
      this.identifierTab.changeIntegration(i);
    }

    if (this.xmlSelected) {
      this.monaco?.changeLanguage('xml');
      this.monacoData = this.integration.xmlConfig;
    }
    else {
      this.monaco?.changeLanguage('javascript');
      this.monacoData = "";
      for (var js of this.integration.javaScript) {
        if (js.destinationEndpointId == this.javaScript.destinationEndpointId) {
          this.monacoData = js.javaScript;
        }
      }
    }

    if (this.integration.serverId != undefined &&
      this.integration.serverId != "0") {
      this.integrationServer = this.serverList.find(
        (x) => x.serverId == this.integration.serverId
      );
      this.serverDescription = "Running on " + this.integrationServer.description;
    } else {
      this.serverDescription = "Not Currently Running on the Server";
    }
  }

  getEndpointName(destId: number): string {
    let endpointName = this.destinationEndpoints.find(d => d.destinationEndpointId == destId).endpointName;
    return endpointName;
  }

  selectIntegration(i: Integration): void {
    this.titleService.setUpTitle("Client - " + this.client.name + " - " + i.name);

    this.originalIntegrationXML = i.xmlConfig;

    this.updateHTMLComponents();

    if (i.serverId != null && this.integration.serverId != "0") {
      this.serverDescription =
        "Running on " +
        this.serverList.find((s) => s.serverId == i.serverId).description;
    } else {
      this.serverDescription = "Not Currently Running on the Server";
    }

    this.integrationSelected = true;

    this.identifierService
      .getIntegrationIdentifiers(this.integration.integrationId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.identifiers = data;
        if (this.identifiers != null && this.identifiers.length > 0) {
          this.showIdentifiersComments = true;
          this.integrationInfo = [];
          this.identifiers.forEach(id => {
            this.integrationInfo.push(new IntegrationInformation(i, id))
          });
        }
        else {
          this.showIdentifiersComments = false;
        }
      });

    this.customQueriesService
      .getAllCustomQueriesByIntegration(this.integration.integrationId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.customQueries = data;
        if (this.customQueries?.length > 0) {
          this.integrationHasQueries = true;
        }
        else {
          this.integrationHasQueries = false;
        }
      });
  }

  //Component Initialization Methods End

  //Configuration Information Methods Start

  getIdentifiers(): void {
    if (this.integration != null) {
      this.identifierService
        .getIntegrationIdentifiers(this.integration.integrationId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(data => {
          this.identifiers = data;
          if (this.identifiers != null && this.identifiers.length > 0) {
            this.showIdentifiersComments = true;
          }
          else {
            this.showIdentifiersComments = false;
          }
        });
    }
  }

  updateLock(): void {
    if (this.integration.locked) {
      this.integration.locked = false;
      this.clientIntegrationService
        .lockIntegration(this.integration.integrationId, false)
        .subscribe(
          (value) => {
            // on success
            this.toastService.toastCreate("Successfully unlocked integration.", "Success", {
              autoClose: false
            });
          });
    } else {
      this.integration.locked = true;
      this.clientIntegrationService
        .lockIntegration(this.integration.integrationId, true)
        .subscribe(
          (value) => {
            // on success
            this.toastService.toastCreate("Successfully locked integration.", "Success", {
              autoClose: false
            });
          });
    }
  }

  updateRestrictIP(): void {
    if (this.integration.ipRestricted) {
      this.integration.ipRestricted = false;
      this.clientIntegrationService
        .restrictIPIntegration(this.integration.integrationId, false)
        .subscribe(
          (value) => {
            // on success
            this.toastService.toastCreate("Successfully unrestricted ip for integration.", "Success", {
              autoClose: false
            });
          });
    } else {
      this.integration.ipRestricted = true;
      this.clientIntegrationService
        .restrictIPIntegration(this.integration.integrationId, true)
        .subscribe(
          (value) => {
            // on success
            this.toastService.toastCreate("Successfully restricted ip for integration.", "Success", {
              autoClose: false
            });
          });
    }
  }

  updateSelfHosted(): void {
    this.integration.selfHosted = !this.integration.selfHosted;
    this.clientIntegrationService
      .selfHostIntegration(this.integration.integrationId, this.integration.selfHosted)
      .subscribe(
        (value) => {
          // on success
          if (!this.integration.selfHosted){
            this.toastService.toastCreate("Successfully removed integration self hosted status.", "Success", {
              autoClose: false
            });
          }
          else {
            this.toastService.toastCreate("Successfully flagged integration as self hosted.", "Success", {
              autoClose: false
            });
          }
        });
  }

  goBack(): void {
    this.router.navigate(['/clients']);
  }

  selectNewJavaScript(destName: string): void {
    //Save any changes to existing javascript
    for (var js of this.integration.javaScript) {
      if (js.destinationEndpointId == this.javaScript.destinationEndpointId
        && this.javaScript.javaScript != this.monacoData) {
        js.isUpdated = true;
        js.javaScript = this.monacoData;
      }
    }

    let selectedEndpoint = this.destinationEndpoints.find(d => d.endpointName == destName);
    let selectedEndpointIntegrationType = selectedEndpoint.endpointName;
    if (selectedEndpointIntegrationType == 'LGDataSwap') {
      selectedEndpointIntegrationType = 'LimeGear';
    }

    if (selectedEndpoint != undefined) {
      this.javaScript = {
        integrationJavaScriptModuleId: '00000000-0000-0000-0000-000000000000',
        integrationId: this.integration.integrationId,
        integrationTypeId: selectedEndpointIntegrationType,
        createDate: new Date(),
        updatedDate: new Date(),
        javaScript: "",
        isUpdated: true,
        destinationEndpointId: selectedEndpoint.destinationEndpointId
      };

    }

    this.integration.javaScript[this.integration.javaScript.length] = this.javaScript;
    this.monacoData = this.javaScript.javaScript;
  }

  deleteIntegration(): void {

    this.savingInformation = true;

    var confirmMsg =
      "Are you sure you want to delete the selected integration? If it is the only integration for the client, the client will be deleted too.";
    if (window.confirm(confirmMsg)) {
      this.clientIntegrationService.deleteIntegration(this.integration).subscribe(
        (value) => {
          // on success
          this.toastService.toastCreate("Successfully deleted integration (" + this.integration.name + ") and client.", "Success", {
            autoClose: false,
            keepAfterRouteChange: true
          });

          this.checkContractsForDeletedIntegration(this.integration);

          if (this.integrations.length == 1) {
            this.router.navigate(["/clients"]);
          } else {
            this.integrations = this.integrations.filter(
              (i) => i.integrationId !== this.integration.integrationId
            );
            // console.log(this.integrations);
            this.selectNewIntegration(this.integrations[0])
          }
          this.savingInformation = false;
        },
        (error) => {
          this.toastService.toastCreate("Failed to delete integration (" + this.integration.name + ") and client.", "Warning", {
            autoClose: false
          });

          this.savingInformation = false;
        }
      );
    }
    else {
      this.savingInformation = false;
    }
  }

  clientContractToUpdateTermDate: ClientContract[] = null;

  checkContractsForDeletedIntegration(deletedIntegration: Integration): void {
    if (this.clientContracts != null && this.clientContracts.length > 0) {
      var matchingContracts = this.clientContracts.filter(x =>
          x.integrationMappings.length > 0
          && x.integrationMappings.some(y => y.integrationId == deletedIntegration.integrationId) == true
          && x.integrationMappings.some(y => y.integrationId != deletedIntegration.integrationId) == false
      );

      if (matchingContracts != null && matchingContracts.length > 0) {
        this.clientContractToUpdateTermDate = matchingContracts;
        this.dialog.open(this.clientContractDeleteConfirmationDialog);
      }
    }
  }

  updateClientContractTermDate(): void {
    if (this.clientContractToUpdateTermDate != null && this.clientContractToUpdateTermDate.length > 0 && this.clientContractTermDate != null) {
      for (var c of this.clientContractToUpdateTermDate) {
        c.contractTermDate = this.clientContractTermDate;
        var matchingId = this.clientIdentifiers.filter(x => x.identifierIdValue == c.identifierIdValue && x.destinationId == c.destinationEndpointId);
        if (matchingId != null && matchingId.length > 0) {
          c.identifierKeyValue = matchingId[0].identifierKeyValue;
          this.clientContractService.updateClientContract(c)
          .subscribe(
            (value) => {
              this.toastService.toastCreate("Updated Termination Date for Client Contract " + c.clientContractId + ".", "Success");
              this.updateClientContract(c);
            },
            (error) => {
              this.toastService.toastCreate("Failed to Update Termination Date for Client Contract " + c.clientContractId + ".", "Warning", {
                autoClose: false
              });
            }
          );
        }
      }
    }
  }

  deleteJavaScript(): void {
    this.savingInformation = true;

    var confirmMsg =
      "Are you sure you want to delete the selected JavaScript? This will immediately and permanently delete the selected JavaScript.";
    if (!window.confirm(confirmMsg)) {
      this.savingInformation = false;
      return;
    }

    let destEndpointId = this.javaScript.destinationEndpointId;

    // Switch to first available javascript
    if (this.integration.javaScript[0].destinationEndpointId == this.javaScript.destinationEndpointId) {
      if (this.integration.javaScript.length != 1) {
        this.switchJavaScript(this.integration.javaScript[1]);
      }
    }
    else {
      this.switchJavaScript(this.integration.javaScript[0]);
    }

    // Remove javascript from local integration object
    for (let i = 0; i < this.integration.javaScript.length; i++) {
      if (this.integration.javaScript[i].destinationEndpointId == destEndpointId) {
        this.integration.javaScript.splice(i, 1);
      }
    }

    // Call delete javascript
    this.clientIntegrationService.deleteJavaScript(this.integration.integrationId, destEndpointId).subscribe(() => this.savingInformation = false);
  }

  // Open dialog box to add new javascript and only allow choices that aren't chosen yet
  addNewJavaScript(): void {
    this.availableDestinations = [];
    for (var dest of this.validDestinations) {
      this.availableDestinations.push(dest);
    }
    for (let i = 0; i < this.availableDestinations.length; i++) {
      for (let j = 0; j < this.integration.javaScript.length; j++) {
        let endpointName = this.destinationEndpoints.find(d => d.destinationEndpointId == this.integration.javaScript[j].destinationEndpointId).endpointName;
        if (this.availableDestinations[i] == endpointName) {
          this.availableDestinations.splice(i, 1);
        }
      }
    }

    this.dialog.open(this.addJsDialog);
  }

  updateIntegration(): void {
    this.savingInformation = true;

    if (true == this.xmlSelected) {
      this.integration.xmlConfig = this.monacoData;
    }
    else {
      for (var js of this.integration.javaScript) {
        if (js.destinationEndpointId == this.javaScript.destinationEndpointId
          && this.monacoData !== js.javaScript) {
          js.isUpdated = true;
          js.javaScript = this.monacoData;
        }
        else if (js.integrationTypeId == this.javaScript.integrationTypeId
          && this.monacoData !== js.javaScript) {
          js.isUpdated = true;
          js.javaScript = this.monacoData;
        }
      }
    }

    this.integration.clientName = this.client.name;

    this.clientIntegrationService.updateIntegration(this.integration).subscribe(value => {
      // on success
      this.toastService.toastCreate("Successfully updated " + this.integration.name + " integration XML.", "Success", {
        autoClose: false
      });

      this.originalIntegrationXML = this.integration.xmlConfig;

      for (var js of this.integration.javaScript) {
        if (js.isUpdated) {
          //New JavaScript
          if (js.integrationJavaScriptModuleId == '00000000-0000-0000-0000-000000000000') {
            const newJs = js;
            this.clientIntegrationService.addJavaScript(js).subscribe(newJsId => {
              // on success

              this.toastService.toastCreate("Successfully created " + this.integration.name + " " + this.getEndpointName(newJs.destinationEndpointId) + " JavaScript.", "Success", {
                autoClose: false
              });

              newJs.integrationJavaScriptModuleId = newJsId;
              newJs.isUpdated = false;
            });
          }
          //Existing JavaScript
          else {
            const updatedJs = js;
            this.clientIntegrationService.updateJavaScript(js).subscribe(jsID => {
              // on success

              this.toastService.toastCreate("Successfully updated " + this.integration.name + " " + this.getEndpointName(updatedJs.destinationEndpointId) + " JavaScript.", "Success", {
                autoClose: false
              });

              updatedJs.isUpdated = false;
            });
          }
        }
      }

      this.savingInformation = false;
    },
      (error) => {

        this.savingInformation = false;
      });
  }

  updateIntegrationIdentifier(event: IntegrationIdentifier): void {
    let updatedIdentifier = false;
    for (let i = 0; i < this.identifiers.length; i++) {
      if (this.identifiers[i].destinationName == event.destinationName) {
        this.identifiers[i] = event;
        updatedIdentifier = true;
        break;
      }
    }
    if (!updatedIdentifier) {
      this.identifiers.push(event);
    }

    updatedIdentifier = false;
    for (let i = 0; i < this.clientIdentifiers.length; i++) {
      if (this.clientIdentifiers[i].destinationName == event.destinationName) {
        this.clientIdentifiers[i] = event;
        updatedIdentifier = true;
        break;
      }
    }
    if (!updatedIdentifier) {
      this.clientIdentifiers.push(event);
    }
  }

  updateClientContract(event: ClientContract): void {
    let updatedContract = false;
    for (let i = 0; i < this.clientContracts.length; i++) {
      if (this.clientContracts[i].clientContractId == event.clientContractId) {
        this.clientContracts[i] = event;
        updatedContract = true;
        break;
      }
    }
    if (!updatedContract) {
      this.clientContracts.push(event);
    }
  }

  setRunTimesPopulated(event: boolean): void {
    this.runTimesPopulated = event;
  }

  saveData(changingToXML: boolean): void {
    // false is switching to JavaScript and saving XML
    // true is switching to xmlConfig and saving javascript

    this.xmlSelected = changingToXML;

    if (true == changingToXML) {

      //if (this.) {

      //}

      for (var js of this.integration.javaScript) {
        if (js.destinationEndpointId == this.javaScript.destinationEndpointId) {
          if (this.javaScript.javaScript != this.monacoData) {
            js.isUpdated = true;
          }
          js.javaScript = this.monacoData;
        }
      }

      this.monaco?.changeLanguage('xml');
      this.monacoData = this.integration.xmlConfig;
      this.lang = "xml";

    } else {

      this.integration.xmlConfig = this.monacoData;
      this.monaco?.changeLanguage('javascript');
      this.monacoData = "";
      this.lang = "javascript";

      for (var js of this.integration.javaScript) {
        if (js.destinationEndpointId == this.javaScript.destinationEndpointId) {
          this.monacoData = js.javaScript;
        }
      }
    }
  }

  switchJavaScript(j: IntegrationJavaScript): void {
    for (var js of this.integration.javaScript) {
      if (js.destinationEndpointId == this.javaScript.destinationEndpointId
        && this.javaScript.javaScript != this.monacoData) {
        js.isUpdated = true;
        js.javaScript = this.monacoData;
      }
    }

    this.javaScript = j;

    for (var js of this.integration.javaScript) {
      if (js.destinationEndpointId == this.javaScript.destinationEndpointId) {
        this.monacoData = js.javaScript;
      }
    }
  }

  changeServer(server: Server): void {
    this.serverService
      .updateRunningServer(this.integration.integrationId, server.serverId)
      .subscribe(
        (value) => {
          // on success
          this.toastService.toastCreate("Successfully moved integration to App Server " + server.serverId, "Success", {
            autoClose: false
          });
          this.serverDescription = "Running on " + server.description;
          if (server.serverId == "0") {
            this.serverDescription = "Not Currently Running on the Server";
          }
          this.integration.serverId = server.serverId;
        });
  }

  createGenericIntegration(): void {
    this.savingInformation = true;
    this.clientIntegrationService.convertIntegrationToGeneric(this.integration, this.convertCreateNew)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        integration => {
          if (this.convertCreateNew) {
            this.integrations.push(integration);
            this.selectNewIntegration(integration);
            this.savingInformation = false;
          }
          else {
            this.integration.xmlConfig = integration.xmlConfig;
            this.originalIntegrationXML = this.integration.xmlConfig;
            this.savingInformation = false;
          }
        },
        fail => {
          this.savingInformation = false;
        }
      )
  }

  createAPIIntegration(): void {
    this.savingInformation = true;
    this.clientIntegrationService.convertIntegrationToAPI(this.integration)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        integration => {
          this.integrations.push(integration);
          this.selectNewIntegration(integration);
          this.savingInformation = false;
        },
        fail => {
          this.savingInformation = false;
        }
      )
  }

  isAPIIntegration(): boolean {
    return this.integration?.xmlConfig?.toLowerCase().includes("enableelliemaeapi=\"true\"");
  }

  isGenericIntegration(): boolean {
    return this.integration?.xmlConfig?.toLowerCase().includes("dynamictargets=\"true\"");
  }
  //Configuration Information Methods End

  //Integration Metrics Methods
  public saveSpreadsheet(component: any): void {
    component.save();
  }

  //Process Data Methods
  showProcessDataCardView(): void {
    this.showProcessDataCards = true;
    this.showDataSwap = false;
    this.showFastFetch = false;
  }

  showDataSwapView(): void {
    this.showProcessDataCards = false;
    this.showDataSwap = true;
    this.showFastFetch = false;
  }

  showFastFetchView(): void {
    this.showProcessDataCards = false;
    this.showDataSwap = false;
    this.showFastFetch = true;
  }


  //Utility Methods Start
  checkAccess(role: string[]): boolean {
    return this.user.checkAccess(role);
  }

  onTabSelect(e): void {
    // Update URL with link directly to integration
    let clientName = encodeURI(this.client.name).replace("(", "%28").replace(")", "%29");
    let integrationName = encodeURI(this.integration.name).replace("(", "%28").replace(")", "%29");
    window.history.replaceState({}, '', `/editConfig/${clientName}/${integrationName}/${e.index}`);
    this.tabId = e.index;

    this.showProcessDataCardView();
  }

  canDeactivate(): boolean {

    if (true == this.xmlSelected) {
      if (null != this.integration) {
        this.integration.xmlConfig = this.monacoData;
      }
    }
    else {
      for (var js of this.integration.javaScript) {
        js.isUpdated = false;
        if (js.destinationEndpointId == this.javaScript.destinationEndpointId
          && this.monacoData !== js.javaScript) {
          js.isUpdated = true;
          js.javaScript = this.monacoData;
        }
      }
    }

    let canDeactivate = true;


    if (this.integration != null) {
      if (this.originalIntegrationXML != this.integration.xmlConfig) {
        canDeactivate = false;
      }

      for (var js of this.integration.javaScript) {
        if (js.isUpdated) {
          canDeactivate = false;
        }
      }
    }


    return canDeactivate;
  }

  updateHTMLComponents(): void {
    if (this.errorListV2 != undefined) {
      this.errorListV2.integration = this.integration;
      this.errorListV2.ngOnInit();
    }
    
    if (this.integrationRuntime != undefined) {
      this.integrationRuntime.integration = this.integration;
      this.integrationRuntime.ngOnInit();
    }

    if (this.errorFilter != undefined) {
      this.errorFilter.integration = this.integration;
      this.errorFilter.ngOnInit();
    }

    if (this.lbaDataSwap != undefined) {
      this.lbaDataSwap.integration = this.integration;
      this.lbaDataSwap.ngOnInit();
    }

    if (this.fastFetch != undefined) {
      this.fastFetch.integration = this.integration;
      this.fastFetch.ngOnInit();
    }

    if (this.revisionHistory != undefined) {
      this.revisionHistory.integration = this.integration;
      this.revisionHistory.ngOnInit();
    }

    if (this.failedLoanViewTab != undefined) {
      this.failedLoanViewTab.integration = this.integration;
      this.failedLoanViewTab.ngOnInit();
    }
  }

  isValidClientId(id: string): boolean {
    return (/^\d+$/i.test(id));
  }

  isValidGuid(id: string): boolean {
    return (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(id));
  }
  //Utility Methods End

  updateFromHistory(event: any) {
    if (event) {
      this.selectIntegration(this.integration);
    }
  }

  openClientInfoDialog() {
    this.dialog.open(this.clientInfoDialog);
  }

  openClientContractDialog() {
    if (this.contractsLoaded) {
      this.clientContractDialogObject = this.dialog.open(this.clientContractDialog, { height: "85vh", width: "60vw" });
    }
  }

  openEditIntegrationDialog() {
    this.dialog.open(this.editIntegrationDialog);
  }
}
