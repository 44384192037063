import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { msalConfig, loginRequest, protectedResources } from './auth-config';
import { TabStripModule, LayoutModule } from '@progress/kendo-angular-layout';
import { AutoCompleteModule, DropDownsModule, DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { IconsModule, SVGIconModule } from "@progress/kendo-angular-icons";
import { ICON_SETTINGS } from '@progress/kendo-angular-icons';
import { FilterModule } from '@progress/kendo-angular-filter';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ButtonModule, ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogModule, DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { LabelModule } from '@progress/kendo-angular-label';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { MonacoEditorModule, MONACO_PATH } from "@materia-ui/ngx-monaco-editor";
import { ClientIntegrationService } from './services/client-integration.service';
import { ErrorLoggingService } from './services/logging.service';
import { UserService } from './services/user.service';
import { FastFetchService } from './services/fastfetch.service';
import { IdentifierService } from './services/identifier.service';
import { MetricService } from './services/metric.service';
import { ReportService } from './services/report.service';
import { ServerService } from './services/server.service';
import { BaseMappingService } from './services/base-mapping.service';
import { DefaultConfigPlaceholderService } from './services/default-config-placeholder.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ErrorComponent } from './components/error/error.component';
import { UserComponent } from './components/user/user.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { ClientsComponent } from './components/clients/clients.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditConfigComponent } from './components/edit-config/edit-config.component';
import { MonacoPageComponent } from './components/monaco-editor/monaco-editor.component';
import { IntegrationRuntimeComponent } from './components/integration-runtime/integration-runtime.component';
import { ErrorListComponent } from './components/error-list/error-list.component';
import { ErrorMessagesv2Component } from './components/error-messages-v2/error-messages-v2.component';
import { CommonErrorFilterComponent } from './components/common-error-filter/common-error-filter.component';
import { GenericEndpointsComponent } from './components/generic-endpoints/generic-endpoints.component';
import { EditGenericEndpointsComponent } from './components/edit-generic-endpoints/edit-generic-endpoints.component';
import { AddClientComponent } from './components/add-client/add-client.component';
import { AddIntegrationComponent } from './components/add-integration/add-integration.component';
import { ServerIntegrationsComponent } from './components/serverintegrations/serverintegrations.component';
import { ServersComponent } from './components/servers/servers.component';
import { AddIntegrationIdentifierComponent } from './components/add-integration-identifier/add-integration-identifier.component';
import { LBADataswapComponent } from './components/lba-dataswap/lba-dataswap.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { ExcelPasteDirective } from './directives/excel-paste.directive';
import { FastfetchComponent } from './components/fastfetch/fastfetch.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportViewComponent } from './components/report-view/report-view.component';
import { IntegrationReportViewComponent } from './components/report-view/integration-report-view/integration-report-view.component';
import { UndeleteComponent } from './components/report-view/undelete/undelete.component';
import { ToastModule } from './components/toast-message/toast.module';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { BaseMappingComponent } from './components/base-mapping/base-mapping.component';
import { RoleGuard } from './guards/role-guard';
import { EditConfigGuard } from './guards/edit-config-guard';
import { EditGenericEndpointsGuard } from './guards/edit-generic-endpoints-guard';
import { EditDefaultConfigGuard } from './guards/edit-default-config-guard';
import { MonacoDiffPageComponent } from './components/monaco-diff-editor/monaco-diff-editor.component';
import { IntegrationRevisionComponent } from './components/integration-revision/integration-revision.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { IdentifierViewComponent } from './components/identifier-view/identifier-view.component';
import { DefaultConfigsComponent } from './components/default-configs/default-configs.component';
import { EditDefaultConfigsComponent } from './components/edit-default-configs/edit-default-configs.component';
import { AddDefaultConfigsComponent } from './components/add-default-configs/add-default-configs.component';
import { CheckInListDirective } from './directives/check-in-list.directive';
import { CheckNotInListDirective } from './directives/check-not-in-list.directive';
import { AddBaseMapping } from './components/add-base-mapping/add-base-mapping.component';
import { DateRangeFilterCellComponent } from './components/date-range-cell-filter/date-range-cell-filter.component';
import { DropDownFilterCellComponent } from './components/dropdown-cell-filter/dropdown-cell-filter.component';
import { EditBaseMappingComponent } from './components/edit-base-mapping/edit-base-mapping.component';
import { EditBaseMappingGuard } from './guards/edit-base-mapping-guard';
import { EditClientComponent } from './components/edit-client/edit-client.component';
import { EditIntegrationComponent } from './components/edit-integration/edit-integration.component';
import { ErrorReportComponent } from './components/report-view/error-report/error-report.component';
import { ClientConfigurationSecretsComponent } from './components/client-configuration-secrets/client-configuration-secrets.component';
import { ClientConfigurationSecretService } from './services/client-configuration-secret.service';
import { ErrorListGridComponent } from './components/error-list-grid/error-list-grid.component';
import { ErrorListGridV2Component } from './components/error-list-grid-v2/error-list-grid-v2.component';
import { TypeConstantService } from './services/type-constant.service';
import { InvoicingService } from './services/invoicing.service';
import { FieldPathService } from './services/field-path.service';
import { ClientInformationService } from './services/client-information.service';
import { ClientContractService } from './services/client-contract.service';
import { InvoicedLoansReportComponent } from './components/invoiced-loans-report/invoiced-loans-report.component';
import { FieldPathsViewComponent } from './components/field-paths-view/field-paths-view.component';
import { ClientContractViewComponent } from './components/client-contract-view/client-contract-view.component';
import { ViewGenericGridComponent } from './components/view-generic-grid/view-generic-grid.component';
import { InvoicePeriodViewComponent } from './components/invoice-period-view/invoice-period-view.component';
import { DestinationsDropdownViewComponent } from './components/destinations-dropdown-view/destinations-dropdown-view.component';
import { ClientContractComponent } from './components/client-contracts/client-contracts.component';
import { LOSTalkerTitleService } from './services/lostalker-title-service.service';
import { InvoicingLoansGridComponent } from './components/invoicing-loans-grid/invoicing-loans-grid.component';
import { ServiceErrorListGridComponent } from './components/service-error-list-grid/service-error-list-grid.component';
import { InvoicingMetricsReportComponent } from './components/invoicing-metrics-report/invoicing-metrics-report.component';
import { IntegrationRunTimeService } from './services/integration-runtime.service';
import { ClientContractReportComponent } from './components/report-view/client-contract-report/client-contract-report.component';
import { SwaggerInterfaceComponent } from './components/swagger-interface/swagger-interface.component';
import { MappingsReportViewComponent } from './components/report-view/mappings-report-view/mappings-report-view.component';
import { LosTokenViewComponent } from './components/los-token-view/los-token-view.component';
import { LosTokenService } from './services/los-token.service';
import { LosTokenMappingViewComponent } from './components/los-token-mapping-view/los-token-mapping-view.component';
import { SQLQueriesComponent } from './components/sql-queries/sql-queries.component';
import { EditSQLQueryComponent } from './components/edit-sql-query/edit-sql-query.component';
import { CustomSQLQueryService } from './services/sql-queries.service';
import { AddSQLQueryComponent } from './components/add-sql-query/add-sql-query.component';
import { IntegrationinfoReportViewComponent } from './components/report-view/integrationinfo-report-view/integrationinfo-report-view.component';
import { ChangeLogsComponent } from './components/changelogs/changelogs.component';
import { IntegrationBulkReportComponent } from './components/report-view/integration-bulk-report/integration-bulk-report.component';
import { FailedLoansViewComponent } from './components/failed-loans-view/failed-loans-view.component';
import { DateRangeViewComponent } from './shared/components/date-range-view/date-range-view.component';
import { InvoicingMetricsGridComponent } from './components/invoicing-metrics-grid/invoicing-metrics-grid.component';
import { InvoicingSelectViewComponent } from './components/invoicing-select-view/invoicing-select-view.component';
import { InvoicingSelectDateViewComponent } from './components/invoicing-select-date-view/invoicing-select-date-view.component';
import { PageLoadingViewComponent } from './shared/components/page-loading-view/page-loading-view.component';
import { BasicErrorSearchFilterComponent } from './components/basic-error-search-filter/basic-error-search-filter.component';
import { ReportErrorSearchFilterComponent } from './components/report-error-search-filter/report-error-search-filter.component';
import { IntegrationErrorReportComponent } from './components/report-view/integration-error-report/integration-error-report.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(protectedResources.LOSTalkerAPI.endpoint, protectedResources.LOSTalkerAPI.scopes);
  protectedResourceMap.set(protectedResources.APISpecPage.endpoint, protectedResources.APISpecPage.scopes);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: loginRequest
  };
}


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ErrorComponent,
    NavMenuComponent,
    FooterComponent,
    ClientsComponent,
    EditConfigComponent,
    MonacoPageComponent,
    ErrorListComponent,
    ErrorMessagesv2Component,
    CommonErrorFilterComponent,
    GenericEndpointsComponent,
    EditGenericEndpointsComponent,
    EditBaseMappingComponent,
    AddClientComponent,
    AddIntegrationComponent,
    AddIntegrationIdentifierComponent,
    AddBaseMapping,
    BaseMappingComponent,
    LBADataswapComponent,
    CommonErrorFilterComponent,
    ServerIntegrationsComponent,
    ServersComponent,
    FastfetchComponent,
    ExcelPasteDirective,
    NotfoundComponent,
    ReportsComponent,
    ReportViewComponent,
    IntegrationReportViewComponent,
    UndeleteComponent,
    ErrorReportComponent,
    UserComponent,
    MonacoDiffPageComponent,
    IntegrationRevisionComponent,
    AdminDashboardComponent,
    IdentifierViewComponent,
    DefaultConfigsComponent,
    EditDefaultConfigsComponent,
    AddDefaultConfigsComponent,
    CheckInListDirective,
    CheckNotInListDirective,
    DateRangeFilterCellComponent,
    DropDownFilterCellComponent,
    EditClientComponent,
    EditIntegrationComponent,
    ErrorListGridComponent,
    ErrorListGridV2Component,
    InvoicedLoansReportComponent,
    ClientConfigurationSecretsComponent,
    FieldPathsViewComponent,
    ClientContractViewComponent,
    ViewGenericGridComponent,
    InvoicePeriodViewComponent,
    DestinationsDropdownViewComponent,
    ClientContractComponent,
    InvoicingLoansGridComponent,
    InvoicingMetricsReportComponent,
    ServiceErrorListGridComponent,
    ClientContractReportComponent,
    SwaggerInterfaceComponent,
    IntegrationRuntimeComponent,
    MappingsReportViewComponent,
    LosTokenViewComponent,
    LosTokenMappingViewComponent,
    SQLQueriesComponent,
    EditSQLQueryComponent,
    AddSQLQueryComponent,
    IntegrationinfoReportViewComponent,
    ChangeLogsComponent,
    IntegrationBulkReportComponent,
    FailedLoansViewComponent,
    DateRangeViewComponent,
    InvoicingMetricsGridComponent,
    InvoicingSelectViewComponent,
    InvoicingSelectDateViewComponent,
    PageLoadingViewComponent,
    BasicErrorSearchFilterComponent,
    ReportErrorSearchFilterComponent,
    IntegrationErrorReportComponent
  ],
  imports: [
    BrowserModule,
    DropDownListModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    GridModule,
    MatGridListModule,
    BrowserAnimationsModule,
    MonacoEditorModule, // .forRoot() // use forRoot() in main app module only.
    AutoCompleteModule,
    ButtonsModule,
    InputsModule,
    FilterModule,
    DateInputsModule,
    TabStripModule,
    MsalModule,
    DialogModule,
    DialogsModule,
    WindowModule,
    TooltipModule,
    LabelModule,
    ExcelExportModule,
    ExcelModule,
    LayoutModule,
    DropDownsModule,
    ExcelExportModule,
    ExcelModule,
    IconsModule,
    SVGIconModule,
    ToastModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    MatCardModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatTooltipModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    RoleGuard,
    { provide: 'ORIGIN_URL', useValue: location.origin },
    // {
    //   provide: MONACO_PATH,
    //   useValue: "https://unpkg.com/browse/monaco-editor@0.21.3/min/vs/"
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: ICON_SETTINGS,
      useValue: { type: 'font' }
    },
    MsalGuard,
    MsalBroadcastService,
    MsalService,
    ClientIntegrationService,
    ClientConfigurationSecretService,
    ErrorLoggingService,
    UserService,
    BaseMappingService,
    IdentifierService,
    MetricService,
    ReportService,
    FastFetchService,
    ServerService,
    DefaultConfigPlaceholderService,
    TypeConstantService,
    InvoicingService,
    FieldPathService,
    ClientInformationService,
    ClientContractService,
    LOSTalkerTitleService,
    EditConfigGuard,
    EditGenericEndpointsGuard,
    EditDefaultConfigGuard,
    EditBaseMappingGuard,
    IntegrationRunTimeService,
    LosTokenService,
    CustomSQLQueryService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
