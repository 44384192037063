import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { Observable, Subscription, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { TypeConstantService } from 'src/app/services/type-constant.service';
import { DestinationEndpoint } from 'src/app/models/destination-endpoint';
import { ToastService } from '../../services/toast.service';
import { UntypedFormControl } from '@angular/forms';
import { ClientContract } from 'src/app/models/client-contract';
import { DestinationsDropdownViewComponent } from '../destinations-dropdown-view/destinations-dropdown-view.component';
import { ClientIntegrationService } from 'src/app/services/client-integration.service';
import { ClientInformationService } from 'src/app/services/client-information.service';
import { ClientContractService } from 'src/app/services/client-contract.service';
import { Integration } from 'src/app/models/integration';
import { InvoicingService } from 'src/app/services/invoicing.service';
import { MatDialog } from '@angular/material/dialog';
import { InvoicePeriod } from 'src/app/models/invoice-period';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../models/user';

@Component({
  selector: 'app-invoicing-select-view',
  templateUrl: './invoicing-select-view.component.html',
  styleUrls: ['./invoicing-select-view.component.scss']
})
export class InvoicingSelectViewComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  @ViewChild('destinationView') public destinationViewC: DestinationsDropdownViewComponent;
  @ViewChild('contractTableDialog', { static: true }) contractTableDialog: TemplateRef<any>;

  @Input() initClientContractId: string = null; // not needed prob
  @Input() initClientContract: ClientContract = null;
  @Input() showGoToMetrics: boolean = false;
  @Input() initDestination: DestinationEndpoint = null;

  @Output() returnedContract = new EventEmitter<ClientContract | null>();
  @Output() itemSelectedFromOverride = new EventEmitter<ClientContract>();

  @Output() returnFailedLoading = new EventEmitter<boolean | null>();
  @Output() initContractError = new EventEmitter<any>();
  @Output() setGoToMetrics = new EventEmitter<boolean | null>();
  @Output() returnDestination = new EventEmitter<DestinationEndpoint | null>();
  @Output() clearToStart = new EventEmitter<any>();


  @Input() currentInvoicePeriod: InvoicePeriod = null;

  selectedContract: ClientContract = null;
  selectedDestination: DestinationEndpoint = null;
  mappedIntegrations: Integration[] = [];
  haveIntegrations: boolean = false;

  completedDateLogicString: string = "";
  showFundedLogic: boolean = false;

  //Seems like we could just use the 'selected' vars instead of needing these override vars?
  overrideContract: ClientContract = null;
  overrideDestinationId: number = null;

  pageReady: boolean = false;

  public contractTableSelection: ClientContract = null;

  constructor(
    protected invoicingService: InvoicingService,
    protected infoService: ClientInformationService,
    protected contractService: ClientContractService,
    protected toastService: ToastService,
    protected integrationService: ClientIntegrationService,
    private router: Router,
    private dialog: MatDialog,
    private user: User
  ) { }

  checkAccess(role: string[]): boolean {
    if (this.user.checkAccess(role)) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit(): void {
    // from params
    if (this.initClientContractId != null && this.initClientContractId != undefined) {
      this.contractService.getClientContractWithInfoByContractId(this.initClientContractId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((contract) => {

        if (contract == null || contract == undefined) {
          this.initContractError.emit("Contract for Contract Id in route path didn't exist.");
        }
        else {
          //this.selectedContract = contract;
          this.overrideContract = contract;
          this.overrideDestinationId = contract.destinationEndpointId;
          this.itemSelectedFromOverride.emit(this.overrideContract);
          if (this.selectedContract.integrationMappings != null && this.selectedContract.integrationMappings.length > 0) {
            this.getMappedIntegrations();
          }
        }
        this.pageReady = true;
      });
    }

    if (this.initClientContract != null && this.initClientContract != undefined) {
      this.selectedContract = this.initClientContract;
      this.overrideDestinationId = this.selectedContract.destinationEndpointId;
      this.itemSelectedFromOverride.emit(this.selectedContract);
      //this.selectedDestination = this.initDestination;
      if (this.selectedContract.integrationMappings != null && this.selectedContract.integrationMappings.length > 0) {
        this.getMappedIntegrations();
      }
    }

    if (this.currentInvoicePeriod != null && this.currentInvoicePeriod != undefined) {
      this.updateFundedCompletedDateLogic();
    }

  }

  clear(): void {
    this.selectedContract = null;
    this.selectedDestination= null;
    this.currentInvoicePeriod = null;
    this.completedDateLogicString = "";
    this.haveIntegrations = false;
    this.mappedIntegrations = [];
    this.destinationViewC.clearWithoutReturn();
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.currentInvoicePeriod?.currentValue != null && changes?.currentInvoicePeriod?.currentValue != undefined
      && changes?.currentInvoicePeriod?.firstChange != true) {
      this.updateFundedCompletedDateLogic();
    }
  }

  updateFundedCompletedDateLogic() {
    this.showFundedLogic = false;
    this.completedDateLogicString = "";

    if (this.currentInvoicePeriod != null && this.currentInvoicePeriod != undefined) {
      if (this.currentInvoicePeriod?.jsonFieldInfoCollection != null) {
        if (this.currentInvoicePeriod.jsonFieldInfoCollection["completedDate"] != null) {
          this.completedDateLogicString = this.currentInvoicePeriod.jsonFieldInfoCollection["completedDate"];
        }
      }
      this.showFundedLogic = true;
    }

  }

  // from destination
  setFailedLoading(event: any): void {
    if (event == true) {
      this.returnFailedLoading.emit(true);
    }
  }

  openGoToMetrics(): void {
    this.setGoToMetrics.emit(true);
  }

  closeGoToMetrics(): void {
    this.setGoToMetrics.emit(false);
  }

  clearBasics(): void {
    this.selectedDestination = null;
    this.selectedContract = null;
    this.mappedIntegrations = [];
    this.updateFundedCompletedDateLogic();
  }

  clearContractBasics(): void {
    this.updateFundedCompletedDateLogic();
    this.haveIntegrations = false;
    this.mappedIntegrations = [];

  }

  /*
  MAPPED INTEGRATIONS
  */

  getMappedIntegrations(): void {
    this.mappedIntegrations = [];
    for (let inteMap of this.selectedContract.integrationMappings) {
      this.integrationService.getIntegrationByIdNoXML(inteMap.integrationId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((i) => {
          this.mappedIntegrations.push(i);
          if (inteMap == this.selectedContract.integrationMappings[this.selectedContract.integrationMappings.length - 1]) {
            this.haveIntegrations = true;
          }
        });
    }
  }

  goToIntegration(i: Integration): void {
    this.router.navigate(["/editConfig", this.selectedContract.losTalkerClientId, i.integrationId, 0]);
  }

  /*
    Client Contract Dialog
  */
  closeDialog(): void {
    this.dialog.closeAll();
  }

  openContractTableDialog(): void {
    this.contractTableSelection = null;
    if (this.selectedContract != null) {
      this.overrideContract = this.selectedContract;
    }

    this.dialog.open(this.contractTableDialog);
  }

  // Submit selected contract from Client Contract View Table
  submitContractDialogSelect(): void {
    this.closeDialog();
    this.selectedContract = null;
    if (this.contractTableSelection != null) {
      this.selectedContract = this.contractTableSelection;
      this.contractTableSelection = null;
      this.returnedContract.emit(this.selectedContract);

      if (this.selectedContract.integrationMappings != null && this.selectedContract.integrationMappings.length > 0) {
        this.getMappedIntegrations();
      }
    }
  }

  // save temp selection from Client Contract View Table
  saveContractDialogSelection(event: any): void {
    this.contractTableSelection = null;

    if (event != null && event != undefined) {
      this.contractTableSelection = event;
    }
  }

  // initial output from Client Contract View Table when given override and override is initially selected
  updateContractFromOverride(event: any): void {
    this.selectedContract = event;
    this.overrideContract = null;

    if (this.selectedContract.integrationMappings != null && this.selectedContract.integrationMappings.length > 0) {
      this.getMappedIntegrations();
    }
  }

  /*
  DESTINATION
  */

  // get destination
  getDestinationFromView(event: any): void {
    if (event == null || event == undefined || event == '') {
      this.clearToStart.emit(true);
    }
    else {
      this.clearBasics();
      this.selectedDestination = event;
      this.returnDestination.emit(this.selectedDestination);
    }
  }

  // for when passing in override
  updateDestinationFromOverride(event: any): void {
    this.selectedDestination = event;
    this.overrideDestinationId = null;
    this.returnDestination.emit(this.selectedDestination);
  }

  clearDestination(): void {
    this.clearToStart.emit(true);
  }


}
