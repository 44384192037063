export const environment = {
  production: true,
  enableDebuggingTools: false,
  enableImpersonation: true,
  baseURL: "https://www.lostalker.com",
  apiIdentifier: "api://87352752-c8d2-4aa2-95a1-cf5725472e73",
  spaClientId: "146a3e0a-e912-4820-8644-1ae616dd288a",
  azureTenantId: "444d1104-f8cd-4ac9-877f-a7f3c2846d6a",
  environmentName: "Prod"
};
