import { Component, OnInit } from '@angular/core';
import { SwaggerUIBundle } from 'swagger-ui-dist';
import { protectedResources } from '../../auth-config';
import { MsalService } from '@azure/msal-angular';
import { User } from 'src/app/models/user';

//declare const SwaggerUIBundle: any;

@Component({
  selector: 'app-swagger-interface',
  templateUrl: './swagger-interface.component.html',
  styleUrls: ['./swagger-interface.component.scss']
})
export class SwaggerInterfaceComponent implements OnInit {

  constructor(
    private authService: MsalService,
    private user: User) {
    this.activeUser = user;
    this.msalService = authService;
  }
  
  private cachedToken: string;
  private msalService: MsalService;
  private activeUser: User;

  ngOnInit(): void {
    
    this.msalService.instance.acquireTokenSilent(protectedResources.LOSTalkerAPI).then(value => {
      this.cachedToken = value.accessToken
    });

    const ui = SwaggerUIBundle({
      dom_id: '#swagger-ui',
      layout: 'BaseLayout',
      presets: [
        SwaggerUIBundle.presets.apis
      ],
      url: 'assets\\sw2024072612229.json',
      defaultModelsExpandDepth: -1,
      docExpansion: 'none',
      operationsSorter: 'alpha',
      requestInterceptor: (req) => {
        req.headers['Authorization'] = 'Bearer ' + this.cachedToken;
        return req;
      }
    });
  }

}
